<!-- 세로 메뉴바 표시  -->
<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <!-- <li class="nav-item" > -->
          <li class="nav-item mr-auto" >
            <b-link
              class="navbar-brand"
            >
            <!-- to="/" -->
            <!-- 세로 메뉴바 로고  -->
              <span class="brand-logo">
                <b-img
                  :class="[ { 'collapsedImg': isVerticalMenuCollapsed && !isMouseHovered }]"
                  src="../../../../../../public/tdt.svg"
                  alt="logo"
                />
              </span>
              <!-- <h2 class="brand-text">
                {{ appName }}
              </h2> -->
            </b-link>
            
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->
    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />
    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="storeNavMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical' // metabuildmenu 리스트 있는 파일 
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import store from '@/store'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    // adminMenu() {
    //   return store.getters['verticalMenu/adminMenu'];
    // },
    // 메뉴 표시 로직 
    storeNavMenuItems(){
      // 메뉴정보 store에 없는경우 js파일에서 직접 가져오기 위해서 
      const navMenuArr = store.getters['verticalMenu/adminMenu'] == null ? navMenuItems : store.getters['verticalMenu/adminMenu'] ;

      const userAvailableMenu = []; // 로그인 사용자가 이용할 수 있는 메뉴 
      const userData = store.getters['verticalMenu/adminUser']; // 로그인한 사용자 권한 

      // 메뉴 위치 맞추기 위해
      // userAvailableMenu.push({
      //   // header: " ",
      // });

      // 권한별 메뉴 표시 항목 필터링 로직 
      navMenuArr.forEach(element => {
        let e = element.pageAuth; // 페이지별 이용 권한 (배열)

        if(e != undefined || e != null ){ // 내장함수 이용시 undefined 나 null 값이면 에러남
          if(userData != null && e.includes(userData.userAuth)){ // 권한 있는경우 실행 
            userAvailableMenu.push(element);
          }
        }
      });
      
      return  userAvailableMenu; // 로그인 사용자 권한 별 이용가능한 메뉴 
    }
  },
  watch: {
    // adminMenu(value) {
    //   console.log(value)
    // },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    // const navMenuItems = store.getters['verticalMenu/adminMenu'] == null ? navMenuItems : store.getters['verticalMenu/adminMenu']; // metabuildmenu 목록
    

    return {
      // navMenuItems ,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
<style scoped>
.collapsedImg {
  max-width: 40px !important;
  margin-top: 20px;

}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  width : 100%;
  max-width : 180px;
  /* max-width : 20%; */
  /* display: inline-block; */
}
.main-menu .navbar-header {
  height: 6.45rem;
}
</style>