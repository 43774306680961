<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->


    <!-- <div class="show-list-btn" id="listToggle" @click="toggleCollapsed" 
    :style="{
        left: isVerticalMenuCollapsed  ? '0px': '260px',
      }"
   >
   <div   v-if="isVerticalMenuCollapsed"   class="show-btn"></div>
  
						<div  v-else  class="hide-btn"></div>
					</div> -->
    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
      style="margin: 0; padding: 0;"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import useVerticalNavMenu from './components/vertical-nav-menu/useVerticalNavMenu'
export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()
    const{ 
      toggleCollapsed,
    } =useVerticalNavMenu()
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,

      //test
      toggleCollapsed
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
// .dark-layout{
//   .show-list-btn{
//   transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
//   border-bottom-right-radius: 0.428rem;
//     border-top-right-radius: 0.428rem;
// background: #282E40;
// position: absolute;
// top: 45%;
// height: 5rem;
// width: 1.6rem;
// cursor: pointer;
// z-index: 1000;
// display: flex;
// justify-content: center;
// align-items: center;
// }
// }
// .show-list-btn{
//   transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
//   border-bottom-right-radius: 0.428rem;
//     border-top-right-radius: 0.428rem;
// background: #fff;
// position: absolute;
// top: 45%;
// height: 5rem;
// width: 1.6rem;
// cursor: pointer;
// z-index: 1000;
// display: flex;
// justify-content: center;
// align-items: center;
// }
// .light .show-list-btn{
// background: #282E40;
// }


 
// .show-list-btn div{
// width: 0.9rem;
//   height: 0.9rem;
//   border-left: 3px solid #625f6e;
//   border-bottom: 3px solid #625f6e;
// }
// .dark-layout .show-list-btn div{
//   border-left: 3px solid #d0d2d6;
//   border-bottom: 3px solid #d0d2d6;
// }

// .light .show-list-btn div{
//   border-left: 3px solid #ffffff;
//   border-bottom: 3px solid #ffffff;
// }

// .show-list-btn .show-btn{
//   transform: rotate(225deg) translate(1px, -2px);
// }

// .show-list-btn .hide-btn{
//   transform: rotate(45deg) translate(1px, -2px);
// }
// .vertical-layout.vertical-menu-modern.menu-collapsed .app-content {
//     margin-left: 25px !important;
// }
</style>
