<template>
  <div>
    <b-modal
      id="versionInfoPopup"
      ref="versionInfoPopup"
      cancel-variant="outline-secondary"
      ok-title="OK"
      centered
      :ok-only="true"
      title="Version"
      size="md"
      hideHeaderClose
      scrollable
      content-class="sectionModal"
    >
      <!--      <b-tabs>-->
      <!--        <b-tab active>-->
      <!--          <template #title>-->
      <!--            <span>현재 버전</span>-->
      <!--          </template>-->
      <b-card style="font-size: 20px">
        <b-row>
          <b-col class="my-5" style="text-align: center">
            <img id="logo" src="@/assets/images/logo/tdt.svg" />
          </b-col>
        </b-row>
        <!-- <b-row v-for="(info, i) in infoJson" :key="i">
              <b-col md="4">{{ i }}</b-col>
              <b-col md="8">{{ info }}</b-col>
            </b-row> -->
        <b-row style="margin-bottom: 1rem">
          <b-col md="4" style="font-size: 1rem">{{ $t("label.L0152") }}</b-col>
          <b-col md="8" style="font-size: 1rem">Klever Twin</b-col>
        </b-row>
        <b-row style="margin-bottom: 1rem">
          <b-col md="4" style="font-size: 1rem">Version</b-col>
          <b-col md="8" style="font-size: 1rem">1.0</b-col>
        </b-row>
        <b-row style="margin-bottom: 1rem">
          <b-col md="4" style="font-size: 1rem">Copyright(c)</b-col>
          <b-col md="8" style="font-size: 1rem"
            >COPYRIGHT ⓒ 2023 METABUILD INC. ALL RIGHTS RESERVED.</b-col
          >
        </b-row>
      </b-card>
      <!--        </b-tab>-->
      <!--        <b-tab class="sectionWrapper">-->
      <!--          <template #title>-->
      <!--            <span>변경 이력</span>-->
      <!--          </template>-->
      <!--          <b-card>-->
      <!--            <app-collapse>-->
      <!--              <app-collapse-item v-for="(tagInfo, i) in tagList" :key="i" :title="tagInfo.tag">-->
      <!--                <span v-html="tagInfo.context"></span>-->
      <!--              </app-collapse-item>-->
      <!--            </app-collapse>-->
      <!--          </b-card>-->
      <!--        </b-tab>-->
      <!--      </b-tabs>-->
    </b-modal>
  </div>
</template>
<script>
import { BModal, BRow, BCol, BTabs, BTab, BCard } from "bootstrap-vue";
// import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
// import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
// import axios from "@/libs/axios";
// import infoJson from "@/../public/version/info.json"

export default {
  name: "VersionInfoPopup",
  components: {
    BModal,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    // AppCollapse,
    // AppCollapseItem,
  },
  data() {
    return {
      // tagList: [],
      // infoList: [],
      // infoJson,
    };
  },
  async created() {
    // await this.getTagList();
  },
  methods: {
    // async getTagList() {
    // this.tagList = [];
    // const tagList = require.context('@/../public/version', true, /^.*\.txt$/);
    // for (const tagName of tagList.keys()) {
    //   const tag = tagName.slice(2, -4);
    //   const context = await this.getTagContext(tag);
    //   this.tagList.push({ tag, context });
    // }
    // },
    // async getTagContext(tag) {
    // let response;
    // try {
    //   response = await axios.get(`http://localhost:8083/version/${tag}.txt`);
    // }
    // catch (e) {
    //   try {
    //     response = await axios.get(`http://localhost:8081/version/${tag}.txt`);
    //   }
    //   catch (e2) {
    //     response = await axios.get(`http://localhost:8080/version/${tag}.txt`);
    //   }
    // }
    // const context = response.data.replaceAll('\n', '<br>');
    // return context;
    // },
    createPopup() {
      this.$refs.versionInfoPopup.show();
    },
  },
};
</script>
