<template>
  <div
    class="navbar-container d-flex content align-items-center"
    style="z-index: 11"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <strong>
        <!-- <b-badge class="mr-1" pill variant="light" style="font-size: 100%">
          {{ $t("label.L0147") }} : {{ currentTimeInterval }}
        </b-badge> -->



        <!-- 현재 시간  -->
        <label class="mr-1" pill variant="light" style="font-size: 100%">
          {{ $t("label.L0147") }} : {{ currentTimeInterval }}
        </label>
      </strong>



      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <!-- <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      @click="backMain()"
    >
      <span class="brand-logo" style="cursor: Default;">
         <b-img
          src="../../../../../public/resources/img/icons/seoul_corporation_logo.svg"
          style="width:90px; "
          alt="logo"
        />
      </span>
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <strong>
        <b-badge
          class="mr-1"
          pill
          variant="light-primary"
          style="font-size:100%"
        >
         {{ $t("label.L0009") }} : {{ userId }}
        </b-badge>
      </strong>
      <!-- 만료 날짜  -->
      <strong v-if="userAuth == 'U'">
        <b-badge
          class="mr-1"
          pill
          variant="light-primary"
          style="font-size:100%"
        >
          {{ $t("label.L0193") }} : {{ expireDate }}
        </b-badge>
      </strong>


      <locale />



      <!-- <span style="font-size: 1.1rem;user-select: none;" class="cursor-pointer" @click="langFunction">{{ langType }}</span> -->
      <!-- <b-dropdown size="sm" :text="selectedSearch" variant="white">
                                    <b-dropdown-item v-for="(search) in searchOptions" :key="search" style="border:0px"
                                        @click="selectSearch(search)">{{ search }}
                                    </b-dropdown-item>
                                </b-dropdown> -->
      <!-- <span class="cursor-pointer"   @click="selectSearch" style="  user-select: none;"> {{ selectedSearch }}</span> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!-- <cart-dro/pdown /> -->
      <!-- <notification-dropdown /> -->



      <user-dropdown />




    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BDropdown,
  BDropdownItem,
  BBadge,
  BImg,
} from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BDropdown,
    BDropdownItem,
    BBadge,
    BImg,
  },
  data() {
    return {
      selectedSearch: "가",
      searchOptions: ["가", "A"],
      currentTime: "",
      timerVersion : null,
      tunnelName: null,
      regDate: null, 
      userId :  null, 
      userAuth : null, 
      expireDate : '9999-99-99', 
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    currentTimeInterval() {

      return this.currentTime;
    },
    // changeLanguage(){
    //   if(this.$i18n.locale == 'ko'){
    //     this.timerVersion = 'ko-KR';
    //   }else if(this.$i18n.locale == 'id'){
    //     this.timerVersion = 'id-ID';
    //   }else{
    //     this.timerVersion = 'en-US';
    //   }
    // },
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    backMain() {
      this.$router.push({ name: "event" });
    },
    selectSearch() {
      // this.selectedSearch=data;
      this.selectedSearch = this.selectedSearch == "가" ? "A" : "가";
      // this.$forceUpdate();
      // this.$i18n.locale = data =='한국어' ? 'ko':'en';
    },
    updateTime() {
      let now = new Date();
      this.currentTime = now.toLocaleTimeString(this.timerVersion);
      // this.currentTime = now.toLocaleTimeString();
    },
    
  },
  mounted() {
    setInterval(this.updateTime, 1000);
  },
  created() {
      this.tunnelName = this.$store.getters["settingConfig/getTarget"]("gs").direction.tunnelName,
      this.regDate = this.$store.getters["verticalMenu/adminUser"].regDate.split('T')[0];
      this.userId = this.$store.getters["verticalMenu/adminUser"].userid;
      this.userAuth = this.$store.getters["verticalMenu/adminUser"].userAuth;

    this.timerVersion = 'ko-KR'
    if(this.$i18n.locale == 'ko'){
      this.timerVersion = 'ko-KR';
    }else if(this.$i18n.locale == 'id'){
      this.timerVersion = 'id-ID';
    }else{
      this.timerVersion = 'en-US';
    }

    let now = new Date();
    this.currentTime = now.toLocaleTimeString(this.timerVersion);
    let addDate = null;

    try{
      if(this.regDate == null && this.regDate == ''){
        this.regDate = '9999-99-99'
        this.expireDate = this.regDate 
      }
      // 7일을 더한 날짜 계산
      addDate = new Date(this.regDate);
      addDate.setDate(addDate.getDate() + 7);
      this.expireDate = this.formatDate(addDate);

    }catch(err){
      this.expireDate ='9999-99-99'
      console.log("🚀 ~ created ~ err:", err)

    }


  },
};
</script>
<style lang="scss" scoped>
.tunnelName {
  background-color: #fff;
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
