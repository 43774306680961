<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <!-- <span class="user-name font-weight-bolder mb-0">  
        <b-badge
          class="mr-1"
          pill
          variant="light-secondary"
          style="font-size:100%"
        >
          ID :   {{ userData.userid }}
        </b-badge>

        </span>
        <span class="user-name font-weight-bolder mb-0"> 
      
            
            
        <b-badge
          class="mr-1"
          pill
          variant="light-secondary"
          style="font-size:100%"
        >
          이름 :   {{ userData.username }}
        </b-badge>
      </span> -->

      <div class="d-sm-flex d-none user-nav"></div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="true" icon="UserIcon" size="22" />
      </b-avatar>

      <versionInfo-modal
        ref="view"
        :propTitle="propTitle"
        :propType="propType"
        @create-obj="versionInfo"
      />
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      class="dropCustomClass"
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t("label.L0148") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="dropCustomClass"
      link-class="d-flex align-items-center"
      @click="openVersionInfoModal()"
    >
      <feather-icon size="16" icon="AlertCircleIcon" class="mr-50" />
      <span>{{ $t("label.L0149") }}</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      class="dropCustomClass"
      link-class="d-flex align-items-center"
      @click="downloadManual()"
    >
    <feather-icon
      size="16"
      icon="FileTextIcon"
      class="mr-50"
    />
    <span>도움말</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      class="dropCustomClass"
      link-class="d-flex align-items-center"
      @click="changeLocale()"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>{{ $t("label.L0150") }} ({{ $i18n.locale }})</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      class="dropCustomClass"
      link-class="d-flex align-items-center"
      @click="changeLocale('en')"
    >
      <img
        src="@/assets/images/flags/en.jpg"
        alt="English"
        style="height: 16px; width: 16px; margin-right: 0.5rem"
      />
      <span>English</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="dropCustomClass"
      link-class="d-flex align-items-center"
      @click="changeLocale('id')"
    >
      <img
        src="@/assets/images/flags/id.jpg"
        alt="Indonesia"
        style="height: 16px; width: 16px; margin-right: 0.5rem"
      />
      <span>Indonesia</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="dropCustomClass"
      link-class="d-flex align-items-center"
      @click="changeLocale('ko')"
    >
      <img
        src="@/assets/images/flags/ko.jpg"
        alt="Korea"
        style="height: 16px; width: 16px; margin-right: 0.5rem"
      />
      <span>한국어</span>
    </b-dropdown-item> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import axios from "@/libs/axios";
import versionInfoModal from "./VersionInfoPopup.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge,
    versionInfoModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      propTitle: "",
      propType: "",
    };
  },
  methods: {
    changeLocale() {
      const languageMap = {
        ko: this.$t("label.L1001"),
        en: this.$t("label.L1002"),
        id: this.$t("label.L1003"),
      };
      const options = Object.keys(languageMap)
        .map((key) => {
          return `<option value="${key}" >${languageMap[key]}</option>`;
        })
        .join("");
      this.$swal({
        title: this.$t("message.M0001"),
        icon: "info",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: this.$t("button.B0001"),
        cancelButtonText: this.$t("button.B0002"),
        reverseButtons: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
        html: `
          <select id="language-select" class="swal2-input">
            ${options}
          </select>
        `,
        focusConfirm: false,
        preConfirm: () => {
          const selectedLanguage = this.$swal
            .getPopup()
            .querySelector("#language-select").value;
          return selectedLanguage;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const selectedLanguage = result.value;
          this.$i18n.locale = selectedLanguage;
          this.$swal({
            icon: "success",
            title: this.$t("button.B0003"),
            customClass: {
              confirmButton: "btn btn-primary ml-1",
              cancelButton: "btn btn-outline-danger",
            },
          });
        }
      });
    },
    changeLocale(value) {
      this.$i18n.locale = value;
      this.$swal({
        icon: "success",
        title: this.$t("button.B0003"),
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
      });
    },
    logout() {
      // axios.get('/logout/' + this.userData.userid)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
      // // Remove userData from localStorage
      // // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // // Reset ability
      // this.$ability.update(initialAbility)

      // // Redirect to login page
      const userId = JSON.parse(localStorage.getItem("userData")).userid;
      this.api.logout({
        userId: userId,
      });

      this.$store.commit("verticalMenu/INIT_ALL_DATA"); // store의 사용자 데이터 초기화
      // localStorage.removeItem("vuex");
      localStorage.removeItem("userData");
      localStorage.removeItem("tabs");
      sessionStorage.removeItem("check");
      this.$cookies.remove("hasToken");
      this.$cookies.remove("accessToken");
      this.jwt.disableToken();
      this.api.removeDefaultHeader("Authorization");
      this.$router.push({ name: "auth-login" });
    },
    openVersionInfoModal() {
      this.propTitle = "About Klever Twin";
      this.propType = "view";
      this.$refs.view.createPopup();
    },
    async versionInfo() {
      await this.$refs.create.hideModal();
    },
    downloadManual() {
      const pdfUrl = "resources/TrafficDigitalTwin_매뉴얼_v.1.0.pdf";
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", "TrafficDigitalTwin_매뉴얼_v.1.0.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  created() {},
};
</script>
<style>
.dropCustomClass {
  background-color: white;
  border: none;
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  margin-top: 10px;
  background-color: white;
  border: none;
}

.swal2-content {
  margin-top: 50px;
  /* margin-bottom : 50px; */
}
</style>
